import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { marked } from 'marked';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { getImageStyles } from '../utils/utils';
import style from './album.module.css';

const Album = ({ data, location: { pathname } }) => {
  return (
    <Layout pathname={pathname} isAlbum>
      <SEO title={data.contentfulAlbum.name} />
      <ul className={style.ul}>
        {data.contentfulAlbum.media &&
          data.contentfulAlbum.media.map(({ id, fixed, file }, i) => {
            const imgStyles = getImageStyles((fixed && fixed.aspectRatio) || 1);
            const albumsJSX = [];

            if (data.contentfulAlbum.embedUrl && i === 1) {
              albumsJSX.push(
                <li className={style.li} key={data.contentfulAlbum.embedUrl}>
                  <iframe
                    src={data.contentfulAlbum.embedUrl}
                    style={{
                      ...getImageStyles(16 / 9),
                      cursor: 'auto',
                      pointerEvents: 'auto',
                    }}
                    title="Embedded video"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  />
                </li>,
              );
            }

            albumsJSX.push(
              <li className={style.li} key={id}>
                {file.contentType.startsWith('video') ? (
                  <video controls className={style.aspectRatio}>
                    <source src={file.url} type={file.contentType} />
                  </video>
                ) : (
                  <Img
                    imgStyle={imgStyles}
                    style={imgStyles}
                    fixed={fixed}
                    loading={i < 3 ? 'eager' : undefined}
                  />
                )}
              </li>,
            );

            return albumsJSX;
          })}
      </ul>
      {data.contentfulAlbum.description ? (
        <div
          className={style.rte}
          dangerouslySetInnerHTML={{
            __html: marked.parse(data.contentfulAlbum.description.description),
          }}
        />
      ) : null}
    </Layout>
  );
};

Album.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    contentfulAlbum: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.shape({
        description: PropTypes.string.isRequired,
      }),
      media: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          fixed: PropTypes.shape({
            aspectRatio: PropTypes.number.isRequired,
            base64: PropTypes.string.isRequired,
            src: PropTypes.string.isRequired,
            srcSet: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
          }),
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
            contentType: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      ).isRequired,
      embedUrl: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query ($id: String!) {
    contentfulAlbum(id: { eq: $id }) {
      name
      description {
        description
      }
      media {
        id
        fixed(height: 1500, quality: 65) {
          ...GatsbyContentfulFixed
          aspectRatio
        }
        file {
          url
          contentType
        }
      }
      embedUrl
    }
  }
`;

export default Album;
